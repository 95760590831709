@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
span,
strong {
    display: inline-block;
}

a {
    text-decoration: none;
}

input.form-control:focus {
    box-shadow: none;
}

.btn.btn-primary {
    background-color: #eb6753;
    border-color: #eb6753;
}

.sectionTitle {
    margin-bottom: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #181a20;
}

p {
    font-size: 15px;
    margin-bottom: 6px;
}

.sectionPD {
    padding: 50px 0;
}

.sectionTitle h2 {
    font-weight: 600;
    font-size: 32px;
    margin: 0;
}

.sectionTitle .titleLine {
    height: 5px;
    background: #eb6753;
    width: 50px;
    border-radius: 30px;
}
.bgGray {
    background-color: #f7f7f7;
}
/* 1. Header */
/* 2. Footer */
/* 3. Home */


/* Common start */
.backTop{
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: #eb6753;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    transition: .4s ease-in-out;
    cursor: pointer;
}
/* Common end */
/* Header start */
.navHeader {
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: 1px solid #353535;
    z-index: 1;
    transition: .4s ease-in-out;
    padding: 15px 0;
}

.header.active .navHeader {
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0px 15px 10px -15px #e9e9e9;
}

.brand {
    width: 40px;
    height: 40px;
    background-color: #eb6753;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 22px;
    justify-content: center;
}

.menuList {
    margin-left: 40px;
}

.menuList .nav-item .nav-link {
    color: #fff;
    font-size: 15px;
}

.menuList .nav-item .nav-link.active {
    color: #eb6753;
}

.copyRight {
    border-top: 1px solid #353535;
    padding: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-menu {
    display: flex;
    column-gap: 5px;
}

.footer-menu a {
    color: #bebdbd;
    font-size: 13px;
}

.exploreMenu {
    display: flex;
    column-gap: 20px;
    align-items: center;
}

.exploreMenu .login span {
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: .4s ease-in-out;
}

.exploreMenu .login:hover i,
.exploreMenu .login:hover span {
    color: #eb6753;
}

.exploreMenu .login {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.exploreMenu .login i {
    color: #fff;
}

.exploreMenu .addProp {
    padding: 8px 28px;
    border: 1px solid white;
    border-radius: 30px;
    transition: .4s ease-in-out;
}

.exploreMenu .addProp:hover {
    color: #fff;
    background-color: #eb6753;
    border-color: #eb6753;
}

.exploreMenu .addProp a {
    color: #fff;
    font-size: 15px;
}

.exploreMenu .bar i {
    font-size: 24px;
    color: #fff;
}

.header.active .menuList .nav-item .nav-link {
    color: #181a20;
}

.header.active .exploreMenu .addProp {
    border-color: #181a20;
}

.header.active .exploreMenu .login span,
.header.active .exploreMenu .addProp a,
.header.active .exploreMenu .bar i,
.header.active .exploreMenu .login i {
    color: #181a20;
}
.header.active .exploreMenu .bar i{
    cursor: pointer;
}

.header.active .exploreMenu .addProp,
.header.active .exploreMenu .addProp a,
.header.active .exploreMenu .addProp:hover,
.header.active .exploreMenu .addProp:hover a {
    border-color: #eb6753;
    color: #fff;
}

.header.active .exploreMenu .addProp {
    background-color: #eb6753;
}

.loginMod {
    width: 440px;
}

.loginMod .modal-content {
    border: 0;
}

.loginMod .modal-header {
    font-size: 13px;
}

.loginMod .modal-title.h4 {
    font-size: 17px;
}

.modal-dialog.loginMod {
    width: 440px;
}

.modal-dialog.loginMod .modal-content {
    border: 0;
}

.modal-dialog.loginMod .modal-header {
    font-size: 13px;
    padding-left: 30px;
}

.modal-dialog.loginMod .modal-title.h4 {
    font-size: 17px;
}

.loginMod .modal-body {
    padding: 20px 30px;
}

.loginMod .nav-link,
.loginMod .nav-link:hover {
    border: 0;
}

.loginMod .nav-link.active {
    color: #eb6753;
    border: 0;
    border-bottom: 2px solid #181a20;
}

.loginMod label.form-label {
    font-size: 13px;
}

.loginMod .nav-link {
    color: #181a20;
}

.loginMod input.form-control {
    height: 50px;
    font-size: 14px;
    border-radius: 10px;
    background: #f7f7f7;
    border: 0;
}

.loginMod button.btn.btn-primary {
    width: 100%;
    height: 50px;
}

.loginMod .mb-3.form-check {
    font-size: 13px;
}

.signInText {
    font-size: 14px;
    text-align: center;
}

.signInText a {
    color: #eb6753;
}

/* Header end */
/* Footer start */
.footer {
    background-color: #181a20;
    padding: 50px 0;
}

.footer-widget .info-title {
    color: #bebdbd;
    margin-bottom: 5px;
}

.footer-widget .info-mail a,
.footer-widget .info-phone a {
    color: #fff;
}

.app-widget .app-info {
    background-color: hsla(0, 0%, 100%, .04);
    border-radius: 12px;
    padding: 10px 20px;
    width: 210px;
    transition: all .4s ease;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.app-info .icon i {
    color: #fff;
    font-size: 24px;
}

.app-info .text p {
    color: #bebdbd;
    font-size: 13px;
}

.app-info .text h6 {
    margin: 0;
    font-size: 14px;
    color: #fff;
}

.app-widget .app-info:hover {
    background-color: #eb6753;
}

.social-style1 a i {
    color: #bebdbd;
    border-radius: 50%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    transition: all .4s ease;
    display: inline-block;
}

.mailchimp-style1 {
    position: relative;
}

.mailchimp-style1 .form-control {
    background-color: hsla(0, 0%, 100%, .04);
    border: none;
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    height: 70px;
    padding-left: 30px;
}

.mailchimp-style1 .form-control::placeholder {
    color: #bebdbd;
    font-weight: 200;
}

.mailchimp-style1 button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
}

.link-style1 a {
    color: #bebdbd;
    display: block;
    line-height: 40px;
    position: relative;
    transition: all .3s ease;
    font-size: 14px;
}

.copyRight {
    border-top: 1px solid #353535;
    padding: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyContent p a {
    color: #eb6753;
}

.copyContent p {
    color: #bebdbd;
    font-size: 14px;
    margin: 0;
}

.footer-menu {
    display: flex;
    column-gap: 5px;
}

.footer-menu a {
    color: #bebdbd;
    font-size: 13px;
}

/* Footer end */

/* Home start */
.banner {
    position: relative;
    height: 620px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bannerContent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}

.bannerContent h6 {
    color: #fff;
}

.bannerContent h1 {
    font-weight: 600;
    font-size: 50px;
    color: #fff;
    margin: 20px 0;
}

.bannerContent p {
    color: #fff;
}

.advanceSearch {
    max-width: 1000px;
    width: 96%;
    margin: auto;
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);

}

.advanceSearch #myTab {
    display: inline-flex;
    padding: 10px 10px 0;
    background: #fff;
    border-radius: 10px 10px 0 0;
}

.advanceSearch #myTab .nav-item .nav-link {
    border: 0;
    color: #181a20;
    font-size: 15px;
}

.advanceSearch #myTab .nav-item .nav-link.active {
    color: #eb6753;
    border-bottom: 2px solid #181a20;
}

.advanceSearch #myTabContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 0 10px 10px 10px;
}

.box-search {
    position: relative;
}

.box-search i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.box-search input {
    padding-left: 35px;
    font-size: 14px;
    height: 55px;
    background: #f7f7f7;
    border: 0;
}

.advance-search-field1 {
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: center;
}

.advance-search-field1 .advance-search-btn {
    background: #fff;
    border: 0;
    font-size: 15px;
}

.apartmentCard {
    background: #f7f7f7;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 24px;
    transition: .4s ease-in-out;
    cursor: pointer;
}

.apartmentCard .icon {
    width: 70px;
    border-radius: 50%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .9s ease-in-out;
    font-size: 25px;
}

.apartmentCard .title {
    margin-top: 70px;
}

.apartmentCard .title h5 {
    font-size: 17px;
    transition: .9s ease-in-out;
    margin-bottom: 5px;
}

.apartmentCard:hover {
    background-color: #181a20;

}

.apartmentCard:hover .icon {
    background-color: hsla(0, 0%, 100%, .04);
    color: #fff;
}

.apartmentCard:hover .title h5,
.apartmentCard:hover .title p {
    color: #fff;
}
.propertyCard{
    margin-bottom: 24px;
}
.propertyCard .image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.propertyCard .image img {
    border-radius: 10px;
    transition: .6s ease-in-out;
}

.propertyCard .title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background: linear-gradient(to right, #181a20, #00000000);
    border-radius: 10px;
    cursor: crosshair;
}

.propertyCard:hover .image img {
    transform: scale(1.1) rotate(-1deg);
}

.propertyCard .title h5 {
    font-size: 30px;
    color: #fff;
    margin: 0;
}

.propertyCard .title p {
    color: #fff;
}

.propertyCard .title a {
    color: #eb6753;
}

.serveCard .title {
    padding: 16px 10px;
}

.serveCard .title p {
    font-size: 13px;
}



.adviceToolCard {
    padding: 20px;
    background: #fff;
    position: relative;
    margin-bottom: 24px;
}

.adviceToolCard .icon i {
    font-size: 30px;
}

.adviceToolCard .title p {
    font-size: 14px;
}

.adviceToolCard .title {
    margin-top: 30px;
}

.adviceToolCard .title a {
    color: #eb6753;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 15px;
    font-weight: 500;
}

.verifiedText {
    position: absolute;
    right: 20px;
    top: -15px;
    background-color: #f90;
    color: #181a20;
    padding: 3px 15px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;

}

.postCard {
    background: #eb6753;
    padding: 30px 0;
}

.postCardInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.postText h5{
    color: #fff;
}
.postProp a {
    background: #fff;
    color: #181a20;
    padding: 14px 30px;
    border-radius: 30px;
}

.postProp a .free {
    background-color: #f90;
    color: #181a20;
    padding: 1px 5px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 15px;
    border-radius: 30px;
    margin-left: 10px;
}
.aboutImg img:last-child {
    margin-left: 40px;
}

.aboutImg img {
    border-radius: 30px;
}

.aboutImg {
    display: flex;
    justify-content: end;
    align-items: end;
}

.aboutInfo ul {
    margin: 40px 0 20px;
}

.aboutInfo ul li i {
    width: 25px;
    height: 25px;
    background: #181a20;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.aboutInfo ul li {
    display: flex;
    column-gap: 10px;
    line-height: 40px;
    align-items: center;
    font-size: 14px;
}

.aboutInfo  a {
    border: 1px solid #181a20;
    color: #181a20;
    padding: 10px 26px;
    text-transform: capitalize;
    border-radius: 10px;
}
.citiesCard {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 24px;
}


.citiesCard .title h5 {
    font-size: 16px;
}

.citiesCard .title p {
    font-size: 14px;
}

.citiesCard .img img {
    border-radius: 10px;
    width: 100px;
    height: 100px;    object-fit: cover;
}
/* Home end */